<template>
  <div class="vehicles vehiclesPage">
    <MyHeader />
    <div class="page-left flex flex-direction">
      <MyTitle title="电池信息" />
      <div class="page-left-demo-1 mt-20">
        <part1 />
      </div>
      <MyTitle title="充电信息" type="2" class="mt-10" />
      <div class="page-left-demo-2 mt-20">
        <part2 />
      </div>
      <MyTitle title="充电参数实时检测" type="2" class="mt-5" />
      <div class="page-left-demo-3 mt-10">
        <part3 />
      </div>
    </div>
    <div class="page-center">
      <div class="page-center-model-1">
        <part7 />
      </div>
      <div class="page-center-model-2">
        <part8 />
      </div>
      <!-- <div class="page-center-model-3">
        <div class="voltage flex flex-direction align-items">
          <div class="currentText">实时电压</div>
          <div>
            <span class="currentNumber">12</span>
            <span class="uuid">V</span>
          </div>
        </div>
      </div>
      <div class="page-center-model-4">
        <div class="current flex flex-direction align-items">
          <div class="currentText">实时电流</div>
          <div>
            <span class="currentNumber">12</span>
            <span class="uuid">A</span>
          </div>
        </div>
      </div> -->
      <div class="charge-status">

    </div>

      <div class="discharge"  v-if="target.status == '车端放电中'"></div>
      <div class="charge" v-else></div>
      <div class="page-center-model-5">
        <part9/>
      </div>
      <div class="page-center-bottom">
        <part6 />
      </div>
    </div>
    <div class="page-right flex flex-direction">
      <MyTitle title="电池包信息" />
      <div class="page-part mt-10">
        <part4 />
      </div>
      <div class="flex1 mt-50">
        <MyTitle title="车辆电池放电数据" />
        <part5 />
      </div>
    </div>
  </div>
</template>

<script>
import MyTitle from "@/views/components/MyTitle.vue";
import MyHeader from "@/views/components/MyHeader.vue";
import part1 from "../components/batterypackPart/Part_1.vue";
import part2 from "../components/batterypackPart/Part_2.vue";
import part3 from "../components/batterypackPart/Part_3.vue";
import part4 from "../components/batterypackPart/Part_4.vue";
import part5 from "../components/batterypackPart/Part_5.vue";
import part6 from "../components/batterypackPart/Part_6.vue";
import part7 from "../components/batterypackPart/Part_7.vue";
import part8 from "../components/batterypackPart/Part_8.vue";
import part9 from "../components/batterypackPart/Part_9.vue";
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    MyTitle,
    MyHeader,
    part1,
    part2,
    part3,
    part4,
    part5,
    part6,
    part7,
    part8,
    part9
  },
  data() {
    return {
      timer: null,
      list: {},
      target: {}
    };
  },
  mounted() {
    this.timer = setTimeout(() =>{
      this.$router.back();
    },1000*15)
    this.getStatus();
    this.getData();
  },
  methods: {
    getStatus() {
      let { batteryId } = this.$route.query;
      let params = {
        enCode: "TD-dianchixiang-sszb",
        "@vin": batteryId || "LMEWWG2R2NEK00047",
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          console.log(res, "判断电池实时指标");
          this.list = res[0] || {};
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData() {
      let { batteryId } = this.$route.query;
      let params = {
        enCode: "TD-dianchixiang-sszb",
        "@vin": batteryId || "LMEWWG2R2NEK00047",
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          console.log(res, "电池实时指标");
          this.target = res[0] || {};
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/comm.scss";
$HeaderHeight: 188px;

.vehicles {
  padding: 0 50px;
  color: #fff;
  .left-p1 {
    width: 455px;
  }
}

.vehiclesPage {
  // display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  background: url("~@/assets/images/batteryId/batteryId-bg.png") no-repeat;
  background-size: 100% 100%;
  //   background: url('~@/assets/images/vehiclesType/Commercial-Bg.png') no-repeat;
  //     background-size: 100% 100%;
  // padding: 0 65px;
  // box-sizing: border-box;
  .page-left,
  .page-right,
  .page-center {
    // position: absolute;
    margin-top: $HeaderHeight;
    height: calc(100% - #{$HeaderHeight});
    // z-index: 10;
  }
  .page-left,
  .page-right {
    // background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    .page-part {
      width: 100%;
      height: 409px;
      // background: orange;
    }
  }
  .page-left {
    width: 1165px;
    // position: absolute;
    // left: 0px;
    // background-color: red;
    .page-left-demo-1,
    .page-left-demo-2,
    .page-left-demo-3 {
      width: 100%;
    }
    .page-left-demo-1 {
      height: 382px;
      // background: red;
    }
    .page-left-demo-2 {
      height: 240px;
    }
    .page-left-demo-3 {
      height: 405px;
      // background: orange;
    }
  }
  .page-right {
    width: 584px;
    // position: absolute;
    // right: 0px;
    // background-color: skyblue;
  }
  .page-center {
    flex: 1;
    // background: url('~@/assets/images/vehiclesType/Commercial-car.png') no-repeat;
    // background-size: 100% 100%;
    .page-center-model-1 {
      position: fixed;
      top: 335px;
      left: 2544px;
      z-index: 10;
    }
    .page-center-model-2 {
      position: fixed;
      top: 1043px;
      left: 1710px;
    }
    .charge-status{
      position: absolute;
      top: 402px;
      left: 1590px;
      width: 1035px;
      height: 603px;
      background: url('~@/assets/images/vehiclesType/battery-bg.png') no-repeat;
      background-size: 100% 100%;
      opacity: 0.5;
    }
    .charge{
      position: absolute;
      top: 402px;
      left: 1590px;
      width: 1035px;
      height: 603px;
      background: url('~@/assets/images/vehiclesType/charge.png') no-repeat;
      background-size: 100% 100%;
      opacity: 0.5;
    }
    .discharge{
      position: absolute;
      top: 402px;
      left: 1590px;
      width: 1035px;
      height: 603px;
      background: url('~@/assets/images/vehiclesType/discharge.png') no-repeat;
      background-size: 100% 100%;
      opacity: 0.5;
    }
    .page-center-model-3 {
      position: fixed;
      top: 685px;
      left: 2910px;
      width: 133px;
      height: 133px;
      // background: orange;
      animation: Beating 1.2s ease infinite;
      background: url("~@/assets/images/vehiclesType/voltage.png") no-repeat;
      background-size: 100% 100%;
      .voltage {
        position: absolute;
        top: 44px;
        left: 40px;
      }
    }
    .page-center-model-4 {
      position: fixed;
      top: 885px;
      left: 2810px;
      width: 133px;
      height: 133px;
      animation: Beating 1.2s ease infinite;
      background: url("~@/assets/images/vehiclesType/current.png") no-repeat;
      background-size: 100% 100%;
      .current {
        position: absolute;
        top: 44px;
        left: 40px;
      }
    }
    .page-center-model-5 {
      position: fixed;
      top: 885px;
      left: 2600px;
      width: 490px;
      height: 220px;
      // background: red;
    }
    .page-center-bottom {
      position: absolute;
      bottom: 15%;
      margin-left: 150px;
      width: 1723px;
      height: 145px;
      // background: skyblue;
    }
  }
}


</style>