<template>
  <div class="box flex flex-wrap justify-content-between">
    <div
      v-for="(item, i) in target1"
      :key="'target1' + i"
      class="flex target1 item-width align-items"
    >
      <img class="img2" :src="item.icon" alt="" />
      <div>
        <div class="label">{{ item.label }}</div>
        <div class="number mt-15">{{ item.number }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  data() {
    return {
      target1: [
        {
          icon: require("@/assets/images/exposition/bat-bg-1.png"),
          number: "",
          label: "电池编号",
        },
        {
          icon: require("@/assets/images/exposition/bat-bg-2.png"),
          number: "",
          label: "记录时间",
        },
        {
          icon: require("@/assets/images/exposition/bat-bg-4.png"),
          number: "",
          label: "当前SOC",
        },
        {
          icon: require("@/assets/images/exposition/bat-bg-5.png"),
          number: "",
          label: "充电电流",
        },
        {
          icon: require("@/assets/images/exposition/bat-bg-3.png"),
          number: "",
          label: "最高单体电压",
        },
        {
          icon: require("@/assets/images/exposition/bat-bg-3.png"),
          number: "",
          label: "最低单体电压",
        },
        {
          icon: require("@/assets/images/exposition/bat-bg-4.png"),
          number: "",
          label: "当前剩余电量",
        },
        {
          icon: require("@/assets/images/exposition/bat-bg-2.png"),
          number: "",
          label: "已经充电时间",
        },
      ],
    };
  },
  mounted() {
    this.getChargingBattery()
  },
  methods: {
    // 充电参数
    getChargingBattery() {
      let { batteryId } = this.$route.query
      let params = {
        enCode: "TiDB-dianchixiang-chongdianguochengdianchixinxi",
        "@battery_id": batteryId || "LMEWWG2R2NEK00047",
      };
      dataInterface.dataFaceApi(params).then((res) => {
        console.log(res, "充电参数实时");
        if (!res || !res.length) return;
        let data = res[0] || {};
        this.target1[0].number = data.batteryNumber || "-";
        this.target1[1].number = data.createTime || "-";
        this.target1[2].number = data.currentSoc || "-";
        this.target1[3].number = data.chgOutputCurr || "-";
        this.target1[4].number = data.maximumCellVoltage || "-";
        this.target1[5].number = data.minimumCellVoltage || "-";
        this.target1[6].number = data.urrentRemainingPowe || "-";
        this.target1[7].number = data.chMinute || "-";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/comm.scss";
.box{
    background: rgba(8, 109, 106, .2);
}
.target1 {
    position: relative;
    // 电池页面用
    &.item-width {
        width: 25%;
        padding: 30px 0;
    }
    .img {
        width: 71px;
        height: 60px;
    }
    .img1 {
        width: 110px;
        height: 73px;
    }
    .img2 {
        margin-left: 40px;
        width: 43px;
        height: 42px;
        margin-right: 15px;
    }
    .img3 {
        width: 132px;
        height: 121px;
    }
    .img4 {
        width: 142px;
        height: 120px;
    }
    .num, .num1 {
        font-size: 24px;
        color: #01D8DD;
        font-weight: bold;
        @include ffamily-DS;
    }
    .num1 {
        font-size: 18px;
    }
    .label {
        color: #C6D1DB;
        font-size: $FontSize24;
    }
    .number{
        font-size: $FontSize20;
        color: #01D8DD;
    }
    .label1 {
        position: absolute;
        top: -5px;
        width: 100%;
        text-align: center;
        font-size: 22px;
        white-space: nowrap;
        @include ffamily-DS;
    }
    .label2 {
        font-size: 16px;
        background: url(~@/assets/images/exposition/chd-bg-5.png) center no-repeat;
        background-size: 100%;
        margin-bottom: 10px;
        width: 100%;
        text-align: center;
        white-space: nowrap;
    }
}
</style>