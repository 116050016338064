<template>
  <div class="model">
    <div class="model-1">
      <div class="address ellipsis">
        <span>电池包ID</span>
        <span>:{{ batteryData.batid }}</span>
      </div>
      <div class="typeBox">
        <div>
          <span>最高温度：</span><span>{{ batteryData.maximumMonomerTemperature }}°C</span>
        </div>
        <div>
          <span>最低温度：</span><span>{{ batteryData.minimumMonomerTemperature }}°C</span>
        </div>
        <div>
          <span>平均温度：</span><span>{{ batteryData.averageimumMonomerTemperature }}°C</span>
        </div>
      </div>
    </div>
    <img class="line-1" src="@/assets/images/vehiclesType/line-1.png" alt="" />
  </div>
</template>
        
<script>
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    //   myEchart,
  },
  data() {
    let { query } = this.$route
    return {
      batteryData: {}, //单车信息位置车牌soc 状态
      query: query
    };
  },
  watch: {
		batteryData(newV) {
			this.batteryData = newV
			this.$set(this.batteryData)
		}
	},
  mounted() {
    this.dianchibaowenduxinxi();
  },
  methods: {
    dianchibaowenduxinxi() {
      let { batteryId } = this.$route.query
      let params = {
        enCode: "TiDB-dianchixiang-dianchibaowenduxinxi",
        "@battery_id": batteryId || 'LMEWWG2R2NEK00047',
      };
      dataInterface.dataFaceApi(params).then((res) => {
        if (!res || !res.length) return;
        console.log(res, 'dianchi')
        this.batteryData = res[0] || {};
      });
    },
  },
};
</script>
        
  <style lang="scss" scoped>
@import "../scss/page.scss";
@import "@/assets/scss/comm.scss";
.model {
  width: 351px;
  height: 207px;
  background: url("~@/assets/images/batteryId/Batterypack-1.png") no-repeat;
  position: relative;

  .model-1 {
    .address {
      padding-top: 15px;
      margin-left: 20px;
      color: #08ffff;
      font-size: $FontSize20;
    }
    .typeBox {
      padding-top: 5px;
      margin-left: 60px;
      font-size: $FontSize24;
      div {
        padding: 8px 0;
      }
    }
  }
  .line-1 {
    position: absolute;
    bottom: -93px;
    left: -134px;
  }
}
</style>