<template>
  <div class="page flex flex-direction">
    <!-- <div class="subBox flex align-items">
      <div
        v-for="(item, index) in subArr"
        :key="'subArr' + index"
        class="label"
      >
        {{ item.label }}
      </div>
    </div> -->
    <div class="echarBox flex1">
      <MyEchart
        :visible="showBatteryCharging"
        :id="'showBatteryCharging'"
        :options="batteryChargingData"
      />
    </div>
  </div>
</template>

<script>
import MyEchart from "@/views/components/charts/echarts";
// import * as echarts from "echarts";
import { dataInterface } from "@/api/dataInterfaceApi";
import { formatTime } from "@/utils/index";
export default {
  components: {
    MyEchart,
  },
  data() {
    return {
      subArr: [
        {
          label: "电压/电流",
        },
        {
          label: "温度",
        },
      ],
      // 充电参数实时检测
      showBatteryCharging: false,
      batteryChargingData: {},
    };
  },
  mounted() {
    this.getbatteryCharging();
  },
  methods: {
    // 充电参数实时检测
    getbatteryCharging() {
      let { batteryId } = this.$route.query;
      let params = {
        enCode: "TiDB-dianchidianyadianliuwenduliebiao",
        "@bn": batteryId || "LMEWWG2R2NEK00047",
      };
      dataInterface.dataFaceApi(params).then((res) => {
        console.log(res, "充电参数实时检测");
        let xAxisData = [];
        let data1 = [];
        let data2 = [];
        let data3 = [];
        let data4 = [];
        let data5 = [];
        let data6 = [];
        res.forEach((item) => {
          xAxisData.push(formatTime(item.ts, "HH:ss"));
          // data1 充电机电流
          data1.push(item.chgOutputCurr);
          // data2 充电机电压
          data2.push(item.currTotalInterVoltV1);
          // data3 电池最高电压
          data3.push(item.batMaxVolt);
          // data4 电池最低电压
          data4.push(item.batMinVolt);
          // data5 最高温度
          data5.push(item.batMaxTemp);
          // data6 最低温度
          data6.push(item.batMinTemp);
        });
        this.batteryChargingData = {
          title: [{
            text: '电压(V)',
            top: 30,
            textStyle: {
              fontSize: 18, // 设置标题字体的大小
            },
          },
           {
            text: '电流(A)',
            right : 0,
            top: 30,
            textStyle: {
              fontSize: 18, // 设置副标题字体的大小
            },
          },
        ],
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: [
              "充电机电流",
              "充电机电压",
              "电池最高电压",
              "电池最低电压",
              "最高温度",
              "最低温度",
            ],
          },
          xAxis: {
            type: "category",
            // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            data: xAxisData,
          },

          yAxis: [
            {
              type: "value",
            },
            {
              type: "value",
              // name: "电流(A)",
            },
          ],
          series: [
            {
              name: "充电机电流",
              // data: [150, 230, 224, 218, 135, 147, 260],
              data: data1,
              type: "line",
              lineStyle: {
                color: "#62F4CA",
              },
              yAxisIndex: 1,
            },
            {
              name: "充电机电压",
              // data: [250, 130, 229, 418, 435, 847, 560],
              data: data2,
              lineStyle: {
                color: "#1A6AFF",
              },
              type: "line",
            },
            {
              name: "电池最高电压",
              // data: [950, 230, 302, 218, 135, 147, 260],
              data: data3,
              lineStyle: {
                color: "#9256AD",
              },
              type: "line",
            },
            {
              name: "电池最低电压",
              // data: [200, 130, 229, 1018, 135, 847, 560],
              data: data4,
              lineStyle: {
                color: "#5B8FF9",
              },
              type: "line",
            },
            {
              name: "最高温度",
              // data: [150, 230, 224, 2018, 135, 1547, 260],
              data: data5,
              lineStyle: {
                color: "#93E59A",
              },
              type: "line",
            },
            {
              name: "最低温度",
              // data: [250, 130, 229, 1418, 2435, 1837, 560],
              data: data6,
              lineStyle: {
                color: "#46FFF5",
              },
              type: "line",
            },
          ],
        };
        this.showBatteryCharging = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/page.scss";
@import "@/assets/scss/comm.scss";
.page {
  height: 100%;
}
.subBox {
  width: 100%;
  height: 75px;
  // background: red;
  .label {
    width: 160px;
    height: 45px;
    color: #fff;
    background: rgba(4, 142, 142, 1);
    text-align: center;
    line-height: 45px;
    margin: 0 40px;
    font-size: $FontSize24;
  }
}

// .echarBox{
//     background: red;
// }
</style>