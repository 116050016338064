<template>
  <div class="page">
    <div class="flex flex-wrap justify-content-around">
      <div
        class="item-text flex align-items"
        v-for="(item, index) in stationInfo"
        :key="'stationInfo' + index"
      >
        <div class="titleName">{{ item.label }}&nbsp;&nbsp;:</div>
        <div class="title-value">{{item.number}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  data() {
    return {
      stationInfo: [
        {
          label: "电池编码",
          number: "",
          uuid: "",
        },
        {
          label: "生产商",
          number: "",
          uuid: "",
        },
        {
          label: "电池类型",
          number: "",
          uuid: "",
        },
        {
          label: "质保年限",
          number: "",
          uuid: "",
        },
        // {
        //   label: "硬件版本号",
        //   number: "",
        //   uuid: "",
        // },
        // {
        //   label: "软件版本号",
        //   number: "",
        //   uuid: "",
        // },
        {
          label: "电池ID",
          number: "",
          uuid: "",
        },
        {
          label: "适用车型",
          number: "",
          uuid: "",
        },
        {
          label: "生产日期",
          number: "",
          uuid: "",
        },
        {
          label: "额定电压",
          number: "",
          uuid: "",
        },
        {
          label: "质保里程",
          number: "",
          uuid: "",
        },
        {
          label: "交付日期",
          number: "",
          uuid: "",
        },
      ],
    };
  },
  mounted() {
    this.getBatteryInfo()
  },
  methods: {
    // 电池信息
    getBatteryInfo() {
      let { batteryId } = this.$route.query
      let params = {
        enCode: "TiDB-Energy-dianchixiang-dianchizhibiao",
        "@battery_id": batteryId || "LMEWWG2R2NEK00047",
      };
      dataInterface.dataFaceApi(params).then((res) => {
        console.log(res, "电池信息111");
        // this.stationInfo = res[0] ? res[0] : {};
        this.stationInfo[0].number =  res[0].batteryNumber || '-'
        this.stationInfo[1].number =  res[0].production || '-'
        this.stationInfo[2].number =  res[0].batteryModel || '-'
        this.stationInfo[3].number =  res[0].warrantyPeriod || '-'
        // this.stationInfo[4].number =  res[0].hardwareVersion || '-'
        // this.stationInfo[5].number =  res[0].softwareVersion || '-'
        this.stationInfo[4].number =  res[0].batteryId || '-'
        this.stationInfo[5].number =  res[0].batteryType || '-'
        this.stationInfo[6].number =  res[0].producedDate || '-'
        this.stationInfo[7].number =  res[0].ratedVoltage || '-'
        this.stationInfo[8].number =  res[0].warrantyMileage || '-'
        this.stationInfo[9].number =  res[0].deliveryDate || '-'
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/page.scss";
@import "@/assets/scss/comm.scss";
.page {
  height: 100%;
}

.item-text {
  width: 532px;
  height: 52px;
  margin: 6px 0;
  background: orange;
  background: url("~@/assets/images/batteryId/battery-bg.png") no-repeat;
  font-size: $FontSize24;
  .titleName {
    width: 250px;
    padding-left: 80px;
  }
  .title-value{
    text-overflow: ellipsis; /* 溢出显示省略号 */
    overflow: hidden; /* 溢出隐藏 */
    white-space: nowrap;  /* 强制不换行 */
  }
}
</style>