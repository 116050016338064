<template>
  <div class="page">
    <dv-scroll-board :config="config1" class="mt-20"></dv-scroll-board>
  </div>
</template>

<script>
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  data() {
    return {
      config1: {
        headerBGC: "rgba(53, 149, 147, 0.2)",
        headerHeight: 42,
        carousel: "single",
        hoverPause: true,
        oddRowBGC: "rgba(53, 149, 147, 0)",
        evenRowBGC: "rgba(53, 149, 147, 0)",
        header: ["充电时间", "电池编码", "充电时长(h)", "充电量(kWH)"],
        data: [],
      },
    };
  },
  mounted() {
    this.getSingleChargeRecord();
  },
  methods: {
    // 单次充电记录
    getSingleChargeRecord() {
      let { batteryId } = this.$route.query;
      let params = {
        enCode: "TiDB-dianchixiang-dancichongdianjilu",
        "@battery_id": batteryId || "LMEWWG2R2NEK00047",
      };
      dataInterface.dataFaceApi(params).then((res) => {
        console.log(res, "单次充电记录");
        let newList = [];
        res.map((item) => {
          let arrList = [
            item.startTime,
            item.batteryId,
            item.chargingTime,
            item.electQuantity,
          ];
          newList.push(arrList);
        });
        this.config1 = {
          headerBGC: "rgba(53, 149, 147, 0.2)",
          headerHeight: 42,
          carousel: "single",
          hoverPause: true,
          oddRowBGC: "rgba(53, 149, 147, 0)",
          evenRowBGC: "rgba(53, 149, 147, 0)",
          rowNum: 6, //表格行数
          header: ["充电时间", "电池编码", "充电时长(h)", "充电量(kWH)"],
          data: newList,
          align: ["center", "center", "center", "center"],
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/page.scss";
@import "@/assets/scss/comm.scss";
.page {
  height: 100%;
}
.dv-scroll-board ::v-deep .header {
  font-size: $FontSize20;
}
.dv-scroll-board ::v-deep .rows .row-item {
  font-size: $FontSize18;
}
</style>