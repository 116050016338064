<template>
  <div class="page flex flex-direction">
    <div class="itemBox flex mt-40 justify-content-between">
      <div
        class="flex flex-direction align-items"
        v-for="(item, index) in target2"
        :key="'target2' + index"
      >
        <!-- <div class="number">{{ item.number }}</div> -->
        <animate-number
            from="0"
            :to="item.number"
            :key="item.number"
            class="number"
          ></animate-number>
        <div class="flex flex-direction align-items">
          <img class="lightning" src="@/assets/images/exposition/lightning.png" alt="">
          <img
            class="itemImg"
            :src="require('@/assets/images/exposition/chd-bg-dummy.png')"
            alt=""
          />
        </div>
        <div class="label mt-20">{{ item.label }}</div>
      </div>
    </div>
    <div class="echarBox mt-40">
      <MyEchart
        :visible="showElectricity"
        :id="'showElectricity'"
        :options="ElectricityTrend"
      />
    </div>
  </div>
</template>

<script>
import { dataInterface } from "@/api/dataInterfaceApi";
import MyEchart from '@/views/components/charts/echarts'
import * as echarts from "echarts";
export default {
  components: {
    MyEchart,
  },
  data() {
    return {
      target2: [
        {
          number: 0,
          label: "累计电量(kWh)",
        },
        {
          number: 0,
          label: "当月电量(kWh)",
        },
      ],
      showElectricity: false,
      ElectricityTrend: {},
      batteryId: "",
    };
  },
  mounted() {
    this.getChargerPowerData1();
    this.getChargerPowerData2();
    this.getElectricityTrend();
  },
  methods: {
    // 充电机电量数据  当月电量
    getChargerPowerData1() {
      let { batteryId } = this.$route.query;
        let params = {
          enCode: "per_vehicle-ljfddl",
          "@VEHICLE_VIN": batteryId || "LMEWWG2R2NEK00047",
        };
        dataInterface.dataFaceApi(params).then((res) => {
          console.log(res, "当月电量1");
          // this.target2[0].label = "当月放电量";
          this.target2[0].number = Number(res[0].discharge_elect).toFixed(0) || 0;
        });
    },
    getChargerPowerData2() {
      let { batteryId } = this.$route.query;
        let params = {
          enCode: "per_vehicle-byfddl",
          "@VEHICLE_VIN": batteryId || "LMEWWG2R2NEK00047",
        };
        dataInterface.dataFaceApi(params).then((res) => {
          console.log(res, "当月电量2");
          // this.target2[1].label = "当月放电量";
          this.target2[1].number = Number(res[0].discharge_elect).toFixed(0) || 0;
        });
    },
    // 充电机电量数据  电量趋势图
    async getElectricityTrend() {
      let { batteryId } = this.$route.query;
      // TD-dianchixiang-dangyuedianliang/TD-dianliangqushitu
      // let nowD = new Date();
      // let monthNow = nowD.getMonth() + 1;
      let res = [];
      let xAxisData = [];
      let data1 = [];

      let params = {
        enCode: "per_vehicle-fangdiandianliangqushitu",
        "@VEHICLE_VIN": batteryId || "LMEWWG2R2NEK00047",
      };
      res = await dataInterface.dataFaceApi(params);
      res.forEach((item) => {
        xAxisData.push(item.cur_date);
        data1.push(item.discharge_elect);
      });
      // res = await dataInterface.dataFaceApi(params);
      // console.log(res, '电量趋势图')
      // res.forEach((item) => {
      //   xAxisData.push(item.ts);
      //   data1.push(item.maxelectmeter);
      // });

      this.ElectricityTrend = {
        title:{
          text: 'kWh'
        },
        color: ["#56C2F1"],
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            data: xAxisData,
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            // name: "电量",
            type: "line",
            // stack: 'Total',
            smooth: true,
            showSymbol: false,
            itemStyle: {
                normal: {
                  color: "rgba(81, 194, 239,1)",
                },
              },
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(81, 194, 239,1)",
                },
                {
                  offset: 1,
                  color: "rgba(131, 209, 240,0)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            // data: [140, 232, 101, 264, 90, 340, 250]
            data: data1,
          },
        ],
      };
      this.showElectricity = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/page.scss";
@import "@/assets/scss/comm.scss";
.page {
  height: 100%;
  .itemBox {
    height: 268px;
    // background: skyblue;
    .itemImg {
      margin-top: -50px;
      // animation: breath_light 3s ease infinite;
    }
    .number {
      color: #01d8dd;
      font-size: $FontSize48;
      @include ffamily-DS;
    }
    .lightning{
      position: relative;
      animation: Beating 1.2s ease infinite;}
      z-index: 10;
    .label {
      width: 260px;
      text-align: center;
      font-size: $FontSize32;
      background: url('~@/assets/images/exposition/text-label.png') no-repeat;
      background-size: 100% 100%;
    }
  }
  .echarBox {
    height: 377px;
  }
}
</style>