<template>
  <div class="page flex justify-content-between" v-if="list.status == '车端放电中'">
    <div class="page-1 flex flex-direction">
      <div class="number">
        <!-- <span>{{ Number(list.elec ).toFixed(0) || '-'}}</span> -->
        <animate-number
          from="0"
          :to="Number(list.elec).toFixed(0)"
          :key="Number(list.elec).toFixed(0)"
          class="number"
        ></animate-number>
        <span class="uuid">kWh</span>
      </div>
      <div class="label">实时电量</div>
    </div>
    <div class="page-2 flex flex-direction">
      <div class="number">
        <!-- <span>{{ Number(list.soc).toFixed(0) || '-' }}</span> -->
        <animate-number
          from="0"
          :to="Number(list.soc).toFixed(0)"
          :key="Number(list.soc).toFixed(0)"
          class="number"
        ></animate-number>
        <span class="uuid">%</span>
      </div>
      <div class="label">SOC</div>
    </div>
  </div>
</template>

<script>
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  data() {
    let { query } = this.$route;
    return {
      list: {
        soc: 0,
        elec: 0,
        status: ''
      },
      query: query,
      timer: null
    };
  },
  mounted() {
    this.getData();
    this.timer = setInterval(() =>{
      this.getData()
    },1*1000)
  },
  methods: {
    getData() {
      let { batteryId } = this.$route.query;
      let params = {
        enCode: "TD-dianchixiang-sszb",
        "@vin": batteryId || "LMEWWG2R2NEK00047",
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          console.log(res, "电池实时指标");
          this.list = res[0] || {};
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/comm.scss";
.page {
  height: 100%;
  width: 100%;
}

.page-1,
.page-2 {
  width: 215px;
  height: 215px;
  background: skyblue;
}
.page-1 {
  background: url("~@/assets/images/batteryId/Electricity.png") no-repeat;
  background-size: 100% 100%;
}
.page-2 {
  background: url("~@/assets/images/batteryId/soc-bg.png") no-repeat;
  background-size: 100% 100%;
}

.number {
  width: 100%;
  height: 100px;
  // background: red;
  font-size: $FontSize64;
  text-align: center;
  margin-top: 40px;
  @include ffamily-DS;
  .uuid {
    font-size: $FontSize28;
    font-family: none;
  }
}
.label {
  width: 100%;
  height: 100px;
  // background: orange;
  font-size: $FontSize24;
  text-align: center;
}
</style>