<template>
  <!-- 电池包-电池list-->
  <div class="batteryOuter hide-scrollbar">
    <div class="batteryCom">
      <div
        class="batteryBagOuter"
        v-for="(item, index) in batteryBagList"
        :key="index"
        :class="index == curIndex ? 'select' : ''"
        @click="clickIndex(item, index)"
      >
        <div
          class="battery-color flex flex-direction justify-content"
          :class="{
            'battery-red': item.st >= 65 && item.sv >= 4,
            'battery-redTem': item.st >= 65 && item.sv < 4,
            'battery-redVol': item.sv >= 4 && item.st < 65,
          }"
        >
          <div class="text-14 ml-20 mt-10">#{{ index | formatterNum }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  data() {
    return {
      batteryHolder: [],
      curIndex: 0, //当前选中电池下标,定义新的变量，防止修改父组件
      batteryBagList: [],
      atItem: {},
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getData();
  },
  methods: {
    getData(data) {
      // 电池单体信息
      this.setBatteryPackageList(data);
    },
    // 点击单个电池
    clickIndex(item, index) {
      if (index != this.curIndex) {
        this.curIndex = index;
        this.atItem = item;
        this.$bus.$emit('atItem', this.atItem);
      }
    },
    // 电池单体信息
    setBatteryPackageList() {
      let { batteryId } = this.$route.query
      let params = {
        enCode: "TiDB-dianchixiang-dianchidantixinxi",
        "@battery_id": batteryId || 'LMEWWG2R2NEK00047',
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (!res || !res.length) return;
          let data = res[0] || {};
          console.log("电池单体信息", data);
          let st = data.st.split("|");
          let stList = st.slice(2, st.length);
          let sv = data.sv.split("|");
          let scList = sv.slice(2, sv.length);
          this.batteryBagList = [...scList].map((item, index) => {
            return {
              sv: item,
              st: parseFloat(stList[index]).toFixed(2) || 0,
            };
          });

          this.atItem = this.batteryBagList[this.curIndex];
          this.$bus.$emit('atItem', this.atItem);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  filters: {
    // 格式化数据
    formatterNum(data) {
      return data < 9 ? "0" + (data + 1) : data + 1;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/comm.scss";

.hide-scrollbar {
  overflow: hidden; /* 隐藏滚动条 */
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0; /* 设置滚动条宽度为0 */
  height: 0;
}
.batteryOuter {
  width: 100%;
  // margin-top: 10%;
  height: 280px;
  overflow-y: scroll;
  position: relative;
  margin-top: 20px;
  // padding-bottom: 150px;

  .batteryBagOuter {
    position: relative;
    cursor: pointer;
    &.select {
      .batteryImg {
        background: url("~@/assets/images/battery/battery_box_select.png");
        background-size: 100% 100%;
      }
    }
    .text-14{
        font-size:$FontSize30 ;
    }
  }

  .point {
    display: block;
    position: absolute;
    width: 165px;
    height: 79px;
    background-image: url("~@/assets/images/batteryBag/bg.png");
    background-size: cover;
    top: 30%;
    right: 40%;
    z-index: 100;
    .inner {
      position: relative;
      width: 184px;
      height: 91px;

      .close {
        position: absolute;
        width: 22px;
        height: 22px;
        background-image: url("~@/assets/images/batteryBag/close.png");
        background-size: cover;
        top: 10px;
        right: 10px;
      }
    }
  }
}

.batteryCom {
  display: grid;
  grid-template-columns: repeat(8, 90px);
  grid-template-rows: repeat(3, 40px);
  grid-row-gap: 59px;
  grid-column-gap: 100px;
  justify-content: center;
}

.battery-color {
  // 单体：默认正常
  width: 120px;
  height: 68px;
  background: url("~@/assets/images/batteryBag/battery_norm.png") no-repeat;
  background-size: 100% 100%;
  position: relative;

  &.battery-red {
    //电压，温度均不正常
    background: url("~@/assets/images/batteryBag/battery_error2.png") no-repeat;
    background-size: 100% 100%;
  }

  &.battery-redTem {
    //温度，不正常
    background: url("~@/assets/images/batteryBag/battery_error1.png") no-repeat;
    background-size: 100% 100%;
  }

  &.battery-redVol {
    //电压，不正常
    background: url("~@/assets/images/batteryBag/battery_error.png") no-repeat;
    background-size: 100% 100%;
  }
}
</style>
