<template>
  <div class="model">
    <img class="line-2" src="@/assets/images/vehiclesType/line-3.png" alt="" />
    <div class="model-2 flex flex-direction">
      <div class="soc">
        <span>当前温度：</span>
        <span>{{ atBattery.st }}°C</span>
      </div>
      <div class="soc">
        <span>当前电压：</span>
        <span>{{ atBattery.sv }}V</span>
      </div>
    </div>
   
  </div>
</template>
        
<script>
export default {
  components: {
    //   myEchart,
  },
  data() {
    return {
      atBattery: {},
    };
  },
  mounted() {
    this.$bus.$on('atItem', (atItem) => {
      this.atBattery = atItem;
    });
    console.log(this.atItem);
  },
  methods: {},
};
</script>
        
  <style lang="scss" scoped>
@import "@/assets/scss/comm.scss";
@import "../scss/page.scss";
.model {
  width: 263px;
  height: 127px;
  background: url("~@/assets/images/batteryId/Batterypack-2.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  .model-2 {
    padding-top: 20px;
  }
  .soc {
    margin: 5px 0;
    margin-left: 30px;
    font-size: $FontSize24;
  }
  .line-2 {
    position: absolute;
    bottom: -95px;
    right: 227px;
  }
}
</style>